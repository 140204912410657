<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-row>
          <b-col cols="6">
            <b-card-header>
              <b-card-title>Günlük Kayıtları</b-card-title>
            </b-card-header>
          </b-col>
          <b-col
            cols="6"
            class="text-right"
          >
            <b-button
              variant="success"
              size="sm"
              class="mt-1 mr-1"
              :disabled="syncStarted"
              @click="startSync"
            >
              <FeatherIcon icon="RefreshCwIcon" />
              Stok Güncelle
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="syncStarted">
          <b-col cols="12">
            <b-card-body class="text-center">
              <b-alert
                variant="warning"
                show
              >
                <h4 class="alert-heading">
                  Güncelleme Yapılıyor...
                </h4>
                <div class="alert-body">
                  <p>Bu işlem bir kaç dakika sürebilir. Lütfen ekranı kapatmayınız.</p>
                  <b-spinner
                    variant="warning"
                    class="mt-1"
                    type="grow"
                  />
                </div>
              </b-alert>
            </b-card-body>
          </b-col>
        </b-row>
        <b-row v-else-if="syncedResult.total">
          <b-col cols="12">
            <b-card-body class="text-center">
              <b-alert
                variant="success"
                show
              >
                <h4 class="alert-heading">
                  Güncelleme Tamamlandı.
                </h4>
                <div class="alert-body mt-2">
                  <p class="font-medium-3">
                    {{ syncedResult.trueCount }} / {{ syncedResult.total }} adet kayıt başarıyla güncellendi.
                  </p>
                </div>
                <b-button
                  class="mb-1 btn-icon"
                  variant="flat-success"
                  pill
                  @click="closeAlert"
                >
                  <FeatherIcon
                    icon="XIcon"
                    size="20"
                  />
                </b-button>
              </b-alert>
            </b-card-body>
          </b-col>
        </b-row>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(synced)="data">
            <div>{{ moment(data.item.synced).format('DD.MM.YYYY HH:mm') }}</div>
            <div class="font-small-3 text-primary">
              {{ data.item.username }}
            </div>
          </template>
          <template #cell(synctype)="data">
            <div class="text-center">
              <b-badge
                v-if="data.item.synctype === 'AUTO'"
                variant="success"
              >
                OTOMATİK
              </b-badge>
              <b-badge
                v-else
                variant="primary"
              >
                MANUEL
              </b-badge>
            </div>
          </template>
          <template #cell(count)="data">
            <div class="text-center">
              {{ data.item.count }} Adet
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/logs/view/' + data.item.id"
              variant="outline-primary"
              size="sm"
              block
            >
              DETAY
            </b-button>
          </template>
        </b-table>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge, BCardBody, BAlert, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'Logs',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    BCardBody,
    BAlert,
    BSpinner,
  },
  data() {
    return {
      syncStarted: false,
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'synced',
          label: 'GÜNCELLEME',
        },
        {
          key: 'count',
          label: 'TOPLAM',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'synctype',
          label: 'GÜNCELLEME TİPİ',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { textAlign: 'center', width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'logs.id AS id',
          'logs.synced AS synced',
          'logs.synctype AS synctype',
          'users.name AS username',
          'count(logs.id) AS count',
        ],
        group_by: 'logs.synced',
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['logs/getListLogs']
    },
    dataCount() {
      return this.$store.getters['logs/getListCount']
    },
    syncedResult() {
      return this.$store.getters['logs/getManuelSynced']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    syncedResult: {
      deep: true,
      handler(val) {
        if (val.total) {
          this.syncStarted = false
          this.getDataList()
        }
      },
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('logs/logsList', this.dataQuery)
    },
    closeAlert() {
      this.$store.dispatch('logs/resetSynced')
    },
    startSync() {
      this.syncedResult.trueCount = 0
      this.syncedResult.total = 0
      this.syncStarted = true
      this.$store.dispatch('logs/manuelSync')
    },
  },
}
</script>
<style scoped></style>
